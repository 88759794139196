<template>
  <div class="email-app-details">

    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="openLeftSidebar(true)"
          />
        </div>
        <span class="go-back ml-1 mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-listview')"
          />
        </span>
        <div class="email-subject mb-0">
          <b-row>
            <div
              class="text-left pt-1 pb-1 pl-2 pr-2 border-1"
            >
              <h4 class="m-0">
                {{ $t('Nova proposta') }}
              </h4>
              <h6 class="m-0 text-primary">
                {{ $t('Selecione uma visita') }}
              </h6>
            </div>
          </b-row>
        </div>
      </div>
    </div>

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxtVisits"
              value=""
              :placeholder="listview_txt_default_visits.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrderVisits.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderVisits"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderByVisits(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderByVisits(oField.name)"
                :class="listviewIconOrderByVisits(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>
      <!-- Share Details -->
      <vue-perfect-scrollbar
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <listview-list-data-visits
          v-if="listviewDataVisits.length > 0"
          ref="list"
          :open-modal-detail-by-row="openModalDetailByRow"
          :show-msg-error-request="showMsgErrorRequest"
        />
        <div
          v-if="listviewDataVisits.length === 0"
          class="no-results"
          :class="{'show': !listviewDataVisits.length}"
        >
          <h5 v-if="listviewInitVisits === 0">
            {{ listview_txt_default_visits.txt }}
          </h5>
          <h5 v-if="listviewInitVisits === 1">
            {{ listview_txt_default_visits.txt_loading }}
          </h5>
        </div>

        <b-container
          v-if="showVisitsPager === true"
          fluid
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPageVisits"
                :total-rows="totalRowsVisits"
                :per-page="perPageVisits"
                class="mb-0 pagination-white pt-0_2 pb-0_2"
                :size="sizePaginationListviewResponsive"
              />
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="$store.getters['crm_proposals/loadingListview']"
        no-wrap
      />

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BContainer, BPagination, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BContainer,
    BPagination,
    BOverlay,
    VuePerfectScrollbar,
    listviewListDataVisits: () => import(/* webpackChunkName: "crm-proposals-listview-data-visits" */ /* webpackPrefetch: true */ './listDataVisits.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
    openLeftSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['listviewDataVisits', 'listviewInitVisits', 'listview_txt_default_visits', 'listviewOrderByVisits', 'fieldsOrderVisits', 'totalRowsVisits', 'perPageVisits', 'showVisitsPager']),
    searchTxtVisits: {
      get() { return this.$store.getters['crm_proposals/searchTxtVisits'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setSearchTxtVisits', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPageVisits: {
      get() { return this.$store.getters['crm_proposals/currentPageVisits'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setCurrentPageVisits', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  methods: {
    listviewFilterOrderByVisits(field) {
      this.$store.dispatch('crm_proposals/listviewFilterOrderByVisits', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderByVisits(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderByVisits.column === filterField) {
        if (this.listviewOrderByVisits.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-app-details {
  width: 100%!important;
  z-index: 3!important;
}

.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

.email-app-details .email-user-list {
  height: calc( 100% - calc(8.4rem) )!important;
}

.ecommerce-application .app-fixed-search {
  padding: 0 0 0 0!important;
}

.ecommerce-application .app-fixed-search input {
  margin-top: 0.4rem;
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}
</style>
